var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"width":"100%"}},[_c('v-row',{staticClass:"flex-column"},[_c('v-col',{staticClass:"flex-shrink-1"},[_c('the-session-data',{attrs:{"session-data":_vm.sessionData,"loading-data":_vm.loadingSessionData}})],1),_c('v-col',[_c('v-row',{staticClass:"justify-space-between"},[(_vm.sessionData.centers)?_c('v-col',{staticClass:"subtitle-2"},[_vm._v(" Alumnos: "+_vm._s(_vm.students.length)+" ")]):_vm._e(),_c('v-spacer'),_c('v-col',{attrs:{"cols":"auto"}},[_c('view-signed-documents')],1),_c('v-col',{attrs:{"cols":"auto"}},[(_vm.sessionData.centers)?_c('add-assistant-btn-dialog',{attrs:{"trainingCourseId":_vm.sessionData.course_id,"centers":_vm.sessionData.centers,"clients":_vm.sessionData.clients},on:{"getSessionData":_vm.getSessionData}}):_vm._e()],1)],1)],1),_c('v-col',{staticClass:"flex-grow-1"},[_c('v-data-table',{attrs:{"loading":_vm.loadingSessionData,"headers":_vm.headers,"items":_vm.students,"fixed-header":"","hide-default-footer":"","items-per-page":-1},scopedSlots:_vm._u([{key:"item.assisted",fn:function(ref){
var item = ref.item;
return [_c('assisted-btn',{attrs:{"assisted":item.assisted,"assistance-id":item.assistance_id,"loading":_vm.updatingAssistance[item.assistance_id]},on:{"handleAssistance":_vm.handleAssistance}})]}},{key:"item.student_tin",fn:function(ref){
var item = ref.item;
return [_c('tin-btn',{attrs:{"student-data":item},on:{"getSessionData":_vm.getSessionData}})]}},{key:"item.student_fullName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.student_name)+" "+_vm._s(item.student_surname)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('student-actions',{attrs:{"student-data":item,"show-test-btn":_vm.showTestBtn,"test-record":item.formRecord},on:{"getSessionData":_vm.getSessionData}})]}},{key:"no-data",fn:function(){return [_vm._v("No hay Alumnos/as en esta sesión")]},proxy:true}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-divider',{staticClass:"my-6"})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('initial-sign-mode-btn')],1),_c('v-spacer'),(_vm.sessionData.centers)?_c('v-col',{staticClass:"text-center",attrs:{"cols":"auto"}},[_c('final-sign-mode-btn',{attrs:{"pending-tests":_vm.pendingTests}})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }