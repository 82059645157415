<template>
  <div style="width: 100%">
    <v-row class="flex-column">
      <v-col class="flex-shrink-1">
        <the-session-data :session-data="sessionData" :loading-data="loadingSessionData" />
      </v-col>

      <v-col>
        <v-row class="justify-space-between">
          <v-col v-if="sessionData.centers" class="subtitle-2">
            Alumnos: {{ students.length }}
          </v-col>

          <v-spacer></v-spacer>

          <v-col cols="auto">
            <view-signed-documents></view-signed-documents>
          </v-col>

          <v-col cols="auto">
            <add-assistant-btn-dialog
              v-if="sessionData.centers"
              :trainingCourseId="sessionData.course_id"
              :centers="sessionData.centers"
              :clients="sessionData.clients"
              @getSessionData="getSessionData"
            />
          </v-col>
        </v-row>
      </v-col>

      <v-col class="flex-grow-1">
        <v-data-table
          :loading="loadingSessionData"
          :headers="headers"
          :items="students"
          fixed-header
          hide-default-footer
          :items-per-page="-1"
        >
          <template #item.assisted="{ item }">
            <assisted-btn
              :assisted="item.assisted"
              :assistance-id="item.assistance_id"
              :loading="updatingAssistance[item.assistance_id]"
              @handleAssistance="handleAssistance"
            />
          </template>

          <template #item.student_tin="{ item }">
            <tin-btn @getSessionData="getSessionData" :student-data="item" />
            <!--            {{ hideTin(item.student_tin) }}-->
          </template>

          <template #item.student_fullName="{ item }">
            {{ item.student_name }} {{ item.student_surname }}
          </template>

          <template #item.actions="{ item }">
            <student-actions
              :student-data="item"
              :show-test-btn="showTestBtn"
              :test-record="item.formRecord"
              @getSessionData="getSessionData"
            />
          </template>

          <template #no-data>No hay Alumnos/as en esta sesión</template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-divider class="my-6"></v-divider>
      </v-col>
      <v-col cols="auto">
        <initial-sign-mode-btn></initial-sign-mode-btn>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="auto" v-if="sessionData.centers" class="text-center">
        <final-sign-mode-btn :pending-tests="pendingTests" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  components: {
    tinBtn: () => {
      return import(
        "@/modules/cardioguard-trainer/components/views/trainer-session/StudentEditData"
      );
    },
    theSessionData: () => {
      return import("@/modules/cardioguard-trainer/components/views/trainer-session/HeaderData");
    },
    assistedBtn: () => {
      return import("@/modules/cardioguard-trainer/components/views/trainer-session/AssistedBtn");
    },
    addAssistantBtnDialog: () => {
      return import(
        "@/modules/cardioguard-trainer/components/views/trainer-session/AddAssistantDialog"
      );
    },
    viewSignedDocuments: () => {
      return import(
        "@/modules/cardioguard-trainer/components/views/trainer-session/ViewSessionSignedDocs"
      );
    },
    studentActions: () => {
      return import(
        "@/modules/cardioguard-trainer/components/views/trainer-session/StudentActions"
      );
    },
    finalSignModeBtn: () => {
      return import(
        "@/modules/cardioguard-trainer/components/views/trainer-session/TerminateSessionBtn"
      );
    },
    initialSignModeBtn: () => {
      return import("@/modules/cardioguard-trainer/components/views/trainer-session/SignModeBtn");
    },
  },
  data: () => ({
    students: [],
    sessionData: {},
    loadingSessionData: true,
    updatingAssistance: {},
    showTestBtn: null,
    headers: [
      { value: "assisted", text: "Asist.", width: 70, sortable: false },
      { value: "student_tin", text: "NIF", sortable: false },
      { value: "student_fullName", text: "Alumno", sortable: false },
      { value: "client_names", text: "Cliente/s", sortable: false },
      { value: "center_names", text: "Centro/s", sortable: false },
      { value: "actions", text: "Acciones", sortable: false, width: 110, align: "right" },
    ],
    currentService: null,
  }),
  beforeMount() {
    this.currentService = this.$store.state.currentService;
  },
  mounted() {
    this.getSessionData();
  },
  methods: {
    async getSessionData() {
      try {
        const practicalBtnSessionTypes = ["practical", "mixed"];
        const response = await this.$store.state.currentService.getSessionData(
          this.$route.params.id
        );
        this.students = response.data.students;
        this.setAssistanceUpdatingKeys();
        this.sessionData = response.data.session;
        this.showTestBtn = practicalBtnSessionTypes.some(t => this.sessionData.type === t);
      } catch (e) {
        this.$notifyError(undefined, "Error al descargar las sesiones | " + e);
      } finally {
        this.loadingSessionData = false;
      }
    },
    async handleAssistance(params) {
      const assistanceId = params.assistance_id;
      try {
        this.updatingAssistance[assistanceId] = true;
        await this.currentService.handleAssistance(params);
        const student = this.students.find(s => s.assistance_id === assistanceId);
        student.assisted = params.assisted;
      } catch (e) {
        this.$notifyError(undefined, "Error al modificar la asistencia | " + e);
      } finally {
        this.updatingAssistance[assistanceId] = false;
      }
    },
    setAssistanceUpdatingKeys() {
      for (const student of this.students) {
        this.$set(this.updatingAssistance, student.assistance_id, null);
      }
    },
    hideTin(tin) {
      const visibleDigits = Math.ceil(tin.length * 0.3);
      const amountAsterisk = tin.length - visibleDigits;
      let asterisks = "";
      for (let i = 0; i < amountAsterisk; i++) {
        asterisks += "*";
      }
      return asterisks + tin.slice(visibleDigits * -1);
    },
  },
  computed: {
    pendingTests() {
      return !!this.students?.filter(s => s.assisted && !s.formRecord).length;
    },
  },
};
</script>

<style scoped></style>
